var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "match-game-card",
      class: {
        "match-game-card--disabled": _vm.disabled,
        "match-game-card--transparent": _vm.transparent,
        "match-game-card--bordered": _vm.bordered,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "match-game-card__cnt",
          style: { backgroundImage: "url('" + _vm.image + "')" },
        },
        [
          _vm.icon
            ? _c(
                "div",
                { staticClass: "match-game-card__icon" },
                [
                  _c("v-icon", { attrs: { large: "", color: "black" } }, [
                    _vm._v(" " + _vm._s(_vm.icon) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }