<template>
  <div
    class="match-game-card"
    :class="{
      'match-game-card--disabled': disabled,
      'match-game-card--transparent': transparent,
      'match-game-card--bordered': bordered
    }"
  >
    <div
      class="match-game-card__cnt"
      :style="{ backgroundImage: `url('${image}')` }"
    >
      <div v-if="icon" class="match-game-card__icon">
        <v-icon large color="black">
          {{ icon }}
        </v-icon>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "MatchGameCard",
  props: {
    imageURL: String,
    disabled: Boolean,
    transparent: Boolean,
    bordered: {
      type: Boolean,
      default: true
    },
    icon: String
  },
  computed: {
    image() {
      return decodeURI(this.imageURL)
    }
  }
}
</script>

<style lang="scss">
.match-game-card {
  $block: &;
  position: relative;

  @supports (aspect-ratio: 1) {
    aspect-ratio: 1;
  }

  @supports not (aspect-ratio: 1) {
    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  &--disabled {
    pointer-events: none;
  }

  &__cnt {
    @extend .rtb-box-shadow;
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    display: flex;
    justify-content: center;
    border-radius: 9px;
    overflow: hidden;
    background-color: $color-white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    #{$block}--bordered & {
      border-width: 2px;
      border-style: solid;
      border-color: $color-white;
    }

    #{$block}--transparent & {
      background-color: transparent;
    }
  }

  &__icon {
    padding-right: 2px;
    padding-top: 2px;
    position: absolute;
    bottom: -1px;
    left: -1px;
    background-color: $color-white;
    border-radius: 0 8px 0 0;
  }
}
</style>
